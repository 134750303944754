<script setup>
import { usePage } from '@inertiajs/vue3';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import Download from '@/Components/Button/Download.vue';
import GradientBar from '@/Components/GradientBar.vue';
import Links from '@/Components/Links/Links.vue';
import Socials from '@/Components/Socials/Socials.vue';

const { t } = useI18n();
const route = inject('route');
const i18n = useI18n();
const locale = computed(() => usePage().props.locale);

const flexableSupportMail = ref('');
const flexablePhoneNumber = ref('');

const currentPage = usePage().props.route.current;

const isEmployerPage = computed(
    () => !!currentPage.includes(route('public.pages.show', { page: i18n.t('employers') })) || !!currentPage.includes(route('public.pages.show', { page: 'employers-alt' })) || !!currentPage.includes(route('public.pages.show', { page: 'werkgevers-alt' }))
);

const footerLinks = computed(() => [
    { href: route('privacy'), text: t('Privacy statement'), blank: true },
    { href: route('terms'), text: t('General terms'), blank: true },
    { href: route('sitemap'), text: t('Sitemap') },
]);

const footerMenu = computed(() => {

    let footerItems = [
        { href: route('home') + '#howitworks', text: t('How does it work?') },
        { href: route('public.pages.show', { page: t('employers') }), text: t('For Employers') },
        { href: route('blogs.index'), text: t('Blog') },
        { href: route('faqs'), text: t('FAQ') },
    ];

    if(locale.value === 'nl') {
        let footerRoute = isEmployerPage.value ? { href: route('employers.recruitment-selection.index'), text: t('Recruitment & selection') } : { href: route('public.job_offers.index'), text: t('Job offers') };

        footerItems = [...footerItems,footerRoute];

    }


    return footerItems;
});

onMounted(async () => {
    flexableSupportMail.value = usePage().props.flexable_support_email;
    flexablePhoneNumber.value = usePage().props.flexable_phone_number;
});
</script>

<template>
    <div class="pb-4 text-base bg-blue-medium">
        <div class="container">
            <div class="flex w-full lg:justify-center">
                <div class="grid gap-8 mt-12 lg:my-20 lg:items-start lg:gap-40 lg:grid-cols-3 min-h-40">
                    <div class="">
                        <img
                            class="mb-3 w-28 lg:w-[200px]"
                            src="@/../img/flexable-logo-sunset.svg"
                            alt="Flexable logo"
                        />
                        <p class="mb-4 text-lg" v-html="$t('Get(s) the retail job done!')"></p>
                        <Download pink ></Download>
                    </div>
                    <div class="flex flex-col justify-center">
                        <h3 class="mb-4 font-semibold tracking-wider uppercase">{{ $t('Contact') }}</h3>

                        <a
                            v-if="flexablePhoneNumber"
                            :href="'tel:' + flexablePhoneNumber"
                            class="block transition-colors duration-300 hover:text-pink"
                        >
                            {{ flexablePhoneNumber }}
                        </a>
                        <a
                            v-if="flexableSupportMail"
                            class="block transition-colors duration-300 hover:text-pink"
                            :href="'mailto:' + flexableSupportMail"
                        >
                            {{ flexableSupportMail }}
                        </a>

                        <p class='font-bold mt-2'>
                            KvK-nummer
                        </p>
                        <span
                            class="block"
                        >
                            87050714
                        </span>
                        <p class='font-bold'>
                        BTW nummer
                        </p>
                        <span
                            class="block mb-2"
                        >
                            NL864188717B01
                        </span>

                        <Socials />
                    </div>
                    <div class="flex flex-col justify-center">
                        <h3 class="mb-4 font-semibold tracking-wider uppercase">{{ $t('Menu') }}</h3>

                        <Links :links="footerMenu" />
                    </div>
                </div>
            </div>
            <Links :links="footerLinks" :vertical="true" class="text-[12px] md:text-base gap-y-3">
                <span>{{ $t('© Flex@ble 2022') }}</span>
            </Links>
        </div>
    </div>

    <GradientBar
        :newWindow="true"
        :href="'https://strixi.nl?utm_source=Developed+sites&utm_medium=Website&utm_campaign=Footer'"
        :text="$t('Made by STRIXI')"
    />
</template>
